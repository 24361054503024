import Box from "@mui/material/Box";
import PlusIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import { WeatherWidgetViewComponentProps } from "features/Weather/entities";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";
import ChevronRight from "@mui/icons-material/ChevronRight";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import { WeatherIcon } from "../WeatherIcon";

export function TabletWeatherWidget({
  externalLink,
  weatherCurrent,
  location,
  weatherDays,
  nextLocation,
  previousLocation,
  showControls,
  nextLocationDisabled,
  previousLocationDisabled,
}: WeatherWidgetViewComponentProps) {
  const tomorrow = weatherDays[0];
  const { t } = useTranslation("Fixhub", {
    keyPrefix: "Next:Weather:TabletWeatherWidget",
  });
  return (
    <>
      <Paper
        sx={{
          backgroundColor: "primary.main",
          color: "primary.contrastText",
          p: 2,
          mb: 1,
          display: "flex",
        }}
      >
        {showControls && (
          <Button
            color="inherit"
            onClick={previousLocation}
            disabled={previousLocationDisabled}
          >
            <ChevronLeft />
          </Button>
        )}

        <Box flex={1}>
          <Typography variant="body2">{location.city}</Typography>
          <Stack direction="row" justifyContent="space-around">
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              gap={4}
            >
              <WeatherIcon code={weatherCurrent.code} fontSize="4rem" />
              <Stack>
                <Typography variant="subtitle1" color="lighten.50">
                  {weatherCurrent.date}
                </Typography>
                <Stack direction="row" gap={2} alignItems="center">
                  <Typography variant="h4">
                    {weatherCurrent.tempCurrent}
                  </Typography>
                  <Stack>
                    <Typography variant="subtitle2" textAlign="end">
                      {weatherCurrent.tempLow}
                    </Typography>
                    <Divider sx={{ backgroundColor: "lighten.50" }} />
                    <Typography variant="subtitle2" textAlign="end">
                      {weatherCurrent.tempHigh}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            {tomorrow && (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                gap={4}
              >
                <WeatherIcon code={tomorrow.conditionCode} fontSize="4rem" />
                <Stack height="100%">
                  <Typography variant="subtitle1" color="lighten.50">
                    {tomorrow.date}
                  </Typography>
                  <Stack direction="row" justifyContent="space-around">
                    <Stack alignItems="center">
                      <Typography variant="body2" color="lighten.50">
                        {t("low")}
                      </Typography>
                      <Typography variant="h5" textAlign="end">
                        {tomorrow.tempLow}
                      </Typography>
                    </Stack>
                    <Stack alignItems="center">
                      <Typography variant="body2" color="lighten.50">
                        {t("high")}
                      </Typography>
                      <Typography variant="h5">{tomorrow.tempHigh}</Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            )}
          </Stack>
        </Box>
        {showControls && (
          <Button
            onClick={nextLocation}
            disabled={nextLocationDisabled}
            color="inherit"
          >
            <ChevronRight />
          </Button>
        )}
      </Paper>

      <Button
        startIcon={<PlusIcon />}
        sx={{ alignSelf: "end", color: "primary.main" }}
        href={externalLink}
        target="_blank"
      >
        {t("moreDetails")}
      </Button>
    </>
  );
}
