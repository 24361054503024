import OpenInNew from "@mui/icons-material/OpenInNew";
import IconButton from "@mui/material/IconButton";
import { MyLinksIcon } from "features/MyLinks/components/MyLinksIcon";
import { useDmsDocumentHref } from "features/DMS/hooks";
import { SearchItemTemplate } from "./SearchItemTemplate";

interface SearchItemDocumentPDFProps {
  fileName: string;
  fileId: string;
  title: string;
  blurb: string;
  dateLabel: string;
  dateText: string;
  isFavorited: boolean;
}

export function SearchItemDocumentPDF({
  fileName,
  fileId,
  title,
  blurb,
  dateLabel,
  dateText,
  isFavorited,
}: SearchItemDocumentPDFProps) {
  const pdfHref = useDmsDocumentHref(fileId, fileName);
  return (
    <SearchItemTemplate
      data-testid="search-item-dms-document-pdf"
      dateLabel={dateLabel}
      dateText={dateText}
      AvatarIcon={<MyLinksIcon itemType="DmsDocument" />}
      secondaryAction={
        <IconButton href={pdfHref} target="_blank">
          <OpenInNew color="primary" />
        </IconButton>
      }
      title={title}
      blurb={blurb}
      isFavorited={isFavorited}
    />
  );
}
