import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export function ExtensoTokenExpired() {
  const { t } = useTranslation("Fixhub");
  // send a message to the parent window to let it know that the token has expired
  useEffect(() => {
    if (!window.parent || window.parent === window) {
      return;
    }
    window.parent.postMessage(
      {
        type: "token-expired",
      },
      "*"
    );
  }, []);
  return (
    <Box
      height="100%"
      display="grid"
      alignItems="center"
      justifyContent="center"
    >
      <Typography data-testid="token-expired">
        {t("Next:Core:ExtensoTokenExpired.message")}
      </Typography>
    </Box>
  );
}
