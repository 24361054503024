import { useRouter } from "next/router";
import { useRegion } from "core/hooks/useRegion";
import { getBrandCode } from "core/utils/getBrandCode";
import { BrandCode, RegionCode } from "core/entities";
import { NewsArticleFilterFormValues } from "../entities";

export function useInitialNewsArticlesFilters(): NewsArticleFilterFormValues {
  const {
    query: { tags: queryTags, categories: queryCategories, types: queryTypes },
  } = useRouter();
  const brand = getBrandCode();
  const region = useRegion();
  const brands: BrandCode[] = brand ? [brand] : [];
  const regions: RegionCode[] = region ? [region] : [];
  const categories: string[] =
    typeof queryCategories === "string" ? [queryCategories] : [];
  const tags: string[] = typeof queryTags === "string" ? [queryTags] : [];
  const types: string[] = typeof queryTypes === "string" ? [queryTypes] : [];
  const sorting = "publishedTime desc";
  const newsFormValues = {
    brands,
    regions,
    categories,
    tags,
    types,
    sorting,
  };
  return newsFormValues;
}
