import SunnyIcon from "@mui/icons-material/WbSunnyOutlined";
import CloudyIcon from "@mui/icons-material/CloudQueue";
import SnowIcon from "@mui/icons-material/AcUnit";
import SevereSnowIcon from "@mui/icons-material/SevereCold";
import ThunderstormIcon from "@mui/icons-material/ThunderstormOutlined";
import { PartlyRainyIcon } from "features/Weather/resources/Icons/PartlyRainy";
// types
import { FogIcon } from "features/Weather/resources/Icons/FogIcon";
import { SnowyRainyIcon } from "features/Weather/resources/Icons/SnowyRainyIcon";
import { RainIcon } from "features/Weather/resources/Icons/RainIcon";
import { HeavyRainIcon } from "features/Weather/resources/Icons/HeavyRainIcon";
import { HailIcon } from "features/Weather/resources/Icons/HailIcon";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

type IconType = (props: SvgIconProps) => JSX.Element;

export const conditionCodeIconMap: Record<
  string,
  {
    IconComponent: typeof SvgIcon | IconType;
    translationKeySuffix: string;
  }
> = {
  "1000": { IconComponent: SunnyIcon, translationKeySuffix: "sunny" },
  "1003": { IconComponent: CloudyIcon, translationKeySuffix: "partlyCloudy" },
  "1006": { IconComponent: CloudyIcon, translationKeySuffix: "cloudy" },
  "1009": { IconComponent: CloudyIcon, translationKeySuffix: "overcast" },
  "1030": { IconComponent: FogIcon, translationKeySuffix: "mist" },
  "1063": {
    IconComponent: PartlyRainyIcon,
    translationKeySuffix: "patchyRainPossible",
  },
  "1066": {
    IconComponent: SnowIcon,
    translationKeySuffix: "patchySnowPossible",
  },
  "1069": {
    IconComponent: RainIcon,
    translationKeySuffix: "patchySleetPossible",
  },
  "1072": {
    IconComponent: SnowyRainyIcon,
    translationKeySuffix: "patchyFreezingDrizzlePossible",
  },
  "1087": {
    IconComponent: ThunderstormIcon,
    translationKeySuffix: "thunderyOutbreaksPossible",
  },
  "1114": { IconComponent: SnowIcon, translationKeySuffix: "blowingSnow" },
  "1117": { IconComponent: SevereSnowIcon, translationKeySuffix: "blizzard" },
  "1135": { IconComponent: FogIcon, translationKeySuffix: "fog" },
  "1147": { IconComponent: FogIcon, translationKeySuffix: "freezingFog" },
  "1150": {
    IconComponent: PartlyRainyIcon,
    translationKeySuffix: "patchyLightDrizzle",
  },
  "1153": {
    IconComponent: PartlyRainyIcon,
    translationKeySuffix: "lightDrizzle",
  },
  "1168": {
    IconComponent: SnowyRainyIcon,
    translationKeySuffix: "freezingDrizzle",
  },
  "1171": {
    IconComponent: SnowyRainyIcon,
    translationKeySuffix: "heavyFreezingDrizzle",
  },
  "1180": {
    IconComponent: PartlyRainyIcon,
    translationKeySuffix: "patchyLightRain",
  },
  "1183": { IconComponent: PartlyRainyIcon, translationKeySuffix: "lightRain" },
  "1186": {
    IconComponent: RainIcon,
    translationKeySuffix: "moderateRainAtTimes",
  },
  "1189": { IconComponent: RainIcon, translationKeySuffix: "moderateRain" },
  "1192": {
    IconComponent: HeavyRainIcon,
    translationKeySuffix: "heavyRainAtTimes",
  },
  "1195": { IconComponent: HeavyRainIcon, translationKeySuffix: "heavyRain" },
  "1198": {
    IconComponent: SnowyRainyIcon,
    translationKeySuffix: "lightFreezingRain",
  },
  "1201": {
    IconComponent: SnowyRainyIcon,
    translationKeySuffix: "moderateOrHeavyFreezingRain",
  },
  "1204": { IconComponent: SnowyRainyIcon, translationKeySuffix: "lightSleet" },
  "1207": {
    IconComponent: SnowyRainyIcon,
    translationKeySuffix: "moderateOrHeavySleet",
  },
  "1210": {
    IconComponent: SnowIcon,
    translationKeySuffix: "patchyLightSnow",
  },
  "1213": { IconComponent: SnowIcon, translationKeySuffix: "lightSnow" },
  "1216": {
    IconComponent: SnowIcon,
    translationKeySuffix: "patchyModerateSnow",
  },
  "1219": { IconComponent: SnowIcon, translationKeySuffix: "moderateSnow" },
  "1222": {
    IconComponent: SevereSnowIcon,
    translationKeySuffix: "patchyHeavySnow",
  },
  "1225": { IconComponent: SevereSnowIcon, translationKeySuffix: "heavySnow" },
  "1237": { IconComponent: HailIcon, translationKeySuffix: "icePellets" },
  "1240": {
    IconComponent: RainIcon,
    translationKeySuffix: "lightRainShower",
  },
  "1243": {
    IconComponent: HeavyRainIcon,
    translationKeySuffix: "moderateOrHeavyRainShower",
  },
  "1246": {
    IconComponent: HeavyRainIcon,
    translationKeySuffix: "torrentialRainShower",
  },
  "1249": {
    IconComponent: SnowyRainyIcon,
    translationKeySuffix: "lightSleetShowers",
  },
  "1252": {
    IconComponent: SnowyRainyIcon,
    translationKeySuffix: "moderateOrHeavySleetShowers",
  },
  "1255": {
    IconComponent: SnowyRainyIcon,
    translationKeySuffix: "lightSnowShowers",
  },
  "1258": {
    IconComponent: SnowyRainyIcon,
    translationKeySuffix: "moderateOrHeavySnowShowers",
  },
  "1261": {
    IconComponent: HailIcon,
    translationKeySuffix: "lightShowersOfIcePellets",
  },
  "1264": {
    IconComponent: HailIcon,
    translationKeySuffix: "moderateOrHeavyShowersOfIcePellets",
  },
  "1273": {
    IconComponent: ThunderstormIcon,
    translationKeySuffix: "patchyLightRainWithThunder",
  },
  "1276": {
    IconComponent: ThunderstormIcon,
    translationKeySuffix: "moderateOrHeavyRainWithThunder",
  },
  "1279": {
    IconComponent: ThunderstormIcon,
    translationKeySuffix: "patchyLightSnowWithThunder",
  },
  "1282": {
    IconComponent: ThunderstormIcon,
    translationKeySuffix: "moderateOrHeavySnowWithThunder",
  },
};
