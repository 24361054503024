import OpenInNew from "@mui/icons-material/OpenInNew";
import { useTranslation } from "react-i18next";
import { useFormatDate } from "core/hooks/useFormatDate";
import Download from "@mui/icons-material/Download";
import IconButton from "@mui/material/IconButton";
import { MyLinksIcon } from "features/MyLinks/components/MyLinksIcon";
import { exhaustiveCheck } from "core/utils/exhaustiveCheck";
import { useDownloadDocumentByFileId } from "features/DMS/hooks";
import { SearchResultDocument } from "../defs";
import { SearchItemTemplate } from "./SearchItemTemplate";
import { SearchItemDocumentPDF } from "./SearchItemDocumentPDF";
import { determineDMSDocumentType } from "../../../utils/determineDMSDocumentType";

export function SearchItemDocument({
  dynamicData,
  title,
  blurb,
  isFavorited,
}: SearchResultDocument) {
  const downloadDoc = useDownloadDocumentByFileId();
  const formatDate = useFormatDate();
  const { t } = useTranslation("Fixhub", { keyPrefix: "Next:Core:SearchItem" });
  const data = determineDMSDocumentType(
    dynamicData.documentLink,
    dynamicData.filename,
    dynamicData.blobName
  );
  const dateLabel = t("publishedTime");
  const formattedDate = formatDate(dynamicData.publishedTime);
  switch (data.type) {
    case "link":
      return (
        <SearchItemTemplate
          data-testid="search-item-dms-document-link"
          dateLabel={dateLabel}
          dateText={formattedDate}
          AvatarIcon={<MyLinksIcon itemType="DmsDocument" />}
          secondaryAction={
            <IconButton href={data.documentLink} target="_blank">
              <OpenInNew color="primary" />
            </IconButton>
          }
          title={title}
          blurb={blurb}
          isFavorited={isFavorited}
        />
      );
    case "pdf":
      return (
        <SearchItemDocumentPDF
          title={title}
          blurb={blurb}
          isFavorited={isFavorited}
          dateLabel={dateLabel}
          dateText={formattedDate}
          fileId={data.fileId}
          fileName={data.fileName}
        />
      );
    case "otherFile":
      return (
        <SearchItemTemplate
          data-testid="search-item-dms-document-file"
          dateLabel={dateLabel}
          dateText={formattedDate}
          AvatarIcon={<MyLinksIcon itemType="DmsDocument" />}
          secondaryAction={
            <IconButton onClick={() => downloadDoc(data.fileId, data.fileName)}>
              <Download color="primary" />
            </IconButton>
          }
          title={title}
          blurb={blurb}
          isFavorited={isFavorited}
        />
      );
    case "invalid":
      return null;
    default:
      exhaustiveCheck(data);
      return null;
  }
}
