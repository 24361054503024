import { useRouter } from "next/router";
import { usePreferredTemperatureUnit } from "./usePreferredTemperatureUnit";

export function useFormatTemperature(options?: Intl.NumberFormatOptions) {
  const { locale } = useRouter();
  const { value: tempUnit } = usePreferredTemperatureUnit();
  const { format } = new Intl.NumberFormat(locale, {
    style: "unit",
    unit: tempUnit === "C" ? "celsius" : "fahrenheit",
    ...options,
  });
  return format;
}
