import { useTranslation } from "react-i18next";
import { useFormatDate } from "core/hooks/useFormatDate";
import IconButton from "@mui/material/IconButton";
import { Link } from "core/components/Link";
import { MyLinksIcon } from "features/MyLinks/components/MyLinksIcon";
import { getMyLinksHref } from "features/MyLinks/utils";
import KeyboardDoubleArrowRight from "@mui/icons-material/KeyboardDoubleArrowRight";
import { SearchResultNews } from "../defs";
import { SearchItemTemplate } from "./SearchItemTemplate";

export function SearchItemNews(props: SearchResultNews) {
  const { t } = useTranslation("Fixhub", { keyPrefix: "Next:Core:SearchItem" });
  const { dynamicData, id, blurb, title, isFavorited } = props;
  const formatDate = useFormatDate();
  return (
    <SearchItemTemplate
      data-testid="search-item-news"
      dateLabel={t("publishedTime")}
      dateText={formatDate(dynamicData.publishedTime)}
      secondaryAction={
        <IconButton LinkComponent={Link} href={getMyLinksHref("News", id)}>
          <KeyboardDoubleArrowRight color="primary" />
        </IconButton>
      }
      AvatarIcon={<MyLinksIcon itemType="News" />}
      title={title}
      blurb={blurb}
      isFavorited={isFavorited}
    />
  );
}
