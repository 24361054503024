import PlusIcon from "@mui/icons-material/Add";
import { WeatherWidgetViewComponentProps } from "features/Weather/entities";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { WeatherIcon } from "../WeatherIcon";

export function MobileWeatherWidget({
  externalLink,
  weatherCurrent,
  location,
  // weatherDays,
  nextLocation,
  previousLocation,
  showControls,
  nextLocationDisabled,
  previousLocationDisabled,
}: WeatherWidgetViewComponentProps) {
  const { t } = useTranslation("Fixhub", {
    keyPrefix: "Next:Weather:MobileWeatherWidget",
  });
  const dayLocationMessage = t("dayLocation", { location: location.city });
  return (
    <Box
      borderTop={(theme) => `1px solid ${theme.palette.divider}`}
      paddingTop={2}
    >
      <Stack direction="row">
        {showControls && (
          <Button
            sx={{ p: 0 }}
            onClick={previousLocation}
            disabled={previousLocationDisabled}
          >
            <ChevronLeft />
          </Button>
        )}
        <Stack
          direction="row"
          flex={1}
          alignItems="center"
          justifyContent="center"
          gap={4}
          color="primary.main"
        >
          <WeatherIcon code={weatherCurrent.code} fontSize="4rem" />
          <Stack>
            <Typography variant="subtitle1" color="text.secondary">
              {dayLocationMessage}
            </Typography>
            <Stack direction="row" gap={2} alignItems="center">
              <Typography variant="h4">{weatherCurrent.tempCurrent}</Typography>
              <Stack color="text.secondary">
                <Typography variant="subtitle2" textAlign="end">
                  {weatherCurrent.tempLow}
                </Typography>
                <Divider sx={{ backgroundColor: "lighten.50" }} />
                <Typography variant="subtitle2" textAlign="end">
                  {weatherCurrent.tempHigh}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        {showControls && (
          <Button
            sx={{ p: 0 }}
            onClick={nextLocation}
            disabled={nextLocationDisabled}
          >
            <ChevronRight />
          </Button>
        )}
      </Stack>
      <Divider>
        <Button
          size="large"
          startIcon={<PlusIcon />}
          target="_blank"
          href={externalLink}
        >
          {t("moreDetails")}
        </Button>
      </Divider>
    </Box>
  );
}
