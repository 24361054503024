import add from "date-fns/add";
import sub from "date-fns/sub";
import endOfDay from "date-fns/endOfDay";
import startOfDay from "date-fns/startOfDay";
import type { TimeFilters, StartEndObject } from "features/NewsEvents/entities";

export function timeFiltersToStartEndDates(
  currentTimeFilters: TimeFilters[]
): StartEndObject {
  const yesterday = sub(new Date(), { days: 1 });
  const today = new Date();
  const tomorrow = add(new Date(), { days: 1 });
  const yesterDayEndOfDay = endOfDay(yesterday);
  const todayStartOfDay = startOfDay(today);
  const todayEndOfDay = endOfDay(today);
  const tomorrowStartOfDay = startOfDay(tomorrow);

  if (currentTimeFilters.includes("past")) {
    return {
      startDateMin: null,
      startDateMax: null,
      endDateMin: null,
      endDateMax: yesterDayEndOfDay,
      sorting: "endDate desc",
    };
  }

  if (
    currentTimeFilters.includes("current") &&
    currentTimeFilters.includes("future")
  ) {
    return {
      startDateMin: null,
      startDateMax: null,
      endDateMin: todayStartOfDay,
      endDateMax: null,
      sorting: "startDate asc",
    };
  }
  if (currentTimeFilters.includes("future")) {
    return {
      startDateMin: tomorrowStartOfDay,
      startDateMax: null,
      endDateMin: null,
      endDateMax: null,
      sorting: "startDate asc",
    };
  }
  if (currentTimeFilters.includes("current")) {
    return {
      startDateMin: null,
      startDateMax: todayEndOfDay,
      endDateMin: todayStartOfDay,
      endDateMax: null,
      sorting: "startDate asc",
    };
  }

  return {
    startDateMin: null,
    startDateMax: null,
    endDateMin: null,
    endDateMax: null,
    sorting: "startDate asc",
  };
}
