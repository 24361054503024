import KeyboardDoubleArrowRight from "@mui/icons-material/KeyboardDoubleArrowRight";
import IconButton from "@mui/material/IconButton";
import { Link } from "core/components/Link";
import { MyLinksIcon } from "features/MyLinks/components/MyLinksIcon";
import { getMyLinksHref } from "features/MyLinks/utils";
import { SearchResult, SearchResultLibrary } from "../defs";
import { SearchItemTemplate } from "./SearchItemTemplate";

export function searchResultIsLibrary(
  input: SearchResult
): input is SearchResultLibrary {
  return input.itemType === "DmsLibrary";
}

export function SearchItemLibrary({
  id,
  title,
  blurb,
  isFavorited,
}: SearchResultLibrary) {
  return (
    <SearchItemTemplate
      data-testid="search-item-dms-library"
      secondaryAction={
        <IconButton
          LinkComponent={Link}
          href={getMyLinksHref("DmsLibrary", id)}
        >
          <KeyboardDoubleArrowRight color="primary" />
        </IconButton>
      }
      AvatarIcon={<MyLinksIcon itemType="DmsLibrary" />}
      title={title}
      blurb={blurb}
      isFavorited={isFavorited}
    />
  );
}
