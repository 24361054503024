import PlusIcon from "@mui/icons-material/Add";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { JoinChildren } from "core/components/JoinChildren";
import { ForwardBackButtons } from "core/components/ForwardBackButtons";
import { WeatherWidgetViewComponentProps } from "features/Weather/entities";
import { useTranslation } from "react-i18next";
import { WeatherIcon } from "../WeatherIcon";

export function DesktopWeatherWidget({
  externalLink,
  weatherCurrent,
  location,
  weatherDays,
  nextLocation,
  nextLocationDisabled,
  previousLocation,
  previousLocationDisabled,
  showControls,
}: WeatherWidgetViewComponentProps) {
  const { t } = useTranslation("Fixhub", {
    keyPrefix: "Next:Weather:DesktopWeatherWidget",
  });
  return (
    <>
      <Paper
        sx={{
          backgroundColor: "primary.main",
          color: "primary.contrastText",
          p: 3,
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Stack
          direction="row"
          alignItems="flex-start"
          justifyContent="space-between"
          mb={4}
        >
          <Typography variant="body2">{`${location.city}`}</Typography>
          {showControls && (
            <ForwardBackButtons
              variant="contained"
              sx={{ color: "primary.main" }}
              disableElevation
              onBackClick={previousLocation}
              onForwardClick={nextLocation}
              backDisabled={previousLocationDisabled}
              forwardDisabled={nextLocationDisabled}
              color="inherit"
              size="small"
            />
          )}
        </Stack>

        <Stack alignItems="center" gap={2}>
          <WeatherIcon code={weatherCurrent.code} fontSize="8rem" />
          <Typography variant="subtitle1" color="lighten.50">
            {weatherCurrent.date}
          </Typography>
          <Stack alignItems="center">
            <Typography variant="body2" color="lighten.50">
              {t("current")}
            </Typography>
            <Typography variant="h3">{weatherCurrent.tempCurrent}</Typography>
          </Stack>
          <Stack direction="row" justifyContent="center" gap={4}>
            <Stack alignItems="center">
              <Typography variant="body2" color="lighten.50">
                {t("low")}
              </Typography>
              <Typography variant="h5">{weatherCurrent.tempLow}</Typography>
            </Stack>
            <Stack alignItems="center">
              <Typography variant="body2" color="lighten.50">
                {t("high")}
              </Typography>
              <Typography variant="h5">{weatherCurrent.tempHigh}</Typography>
            </Stack>
          </Stack>
        </Stack>
        <Divider
          sx={{
            backgroundColor: "lighten.50",
            my: 4,
          }}
        />
        <Stack gap={4} height="100%">
          <JoinChildren
            JoinElement={
              <Divider
                sx={{
                  backgroundColor: "lighten.50",
                }}
              />
            }
          >
            {weatherDays.map(
              ({ id, date, tempHigh, tempLow, conditionCode: code }) => (
                <Box key={id}>
                  <Stack direction="row" gap={2} alignItems="center">
                    <WeatherIcon code={code} fontSize="3rem" />
                    <Stack width="100%" gap={1}>
                      <Typography
                        variant="body1"
                        textAlign="center"
                        color="lighten.50"
                      >
                        {date}
                      </Typography>
                      <Stack direction="row" justifyContent="center" gap={6}>
                        <Stack alignItems="center">
                          <Typography variant="body2" color="lighten.50">
                            {t("low")}
                          </Typography>
                          <Typography variant="h5">{tempLow}</Typography>
                        </Stack>
                        <Stack alignItems="center">
                          <Typography variant="body2" color="lighten.50">
                            {t("high")}
                          </Typography>
                          <Typography variant="h5">{tempHigh}</Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Stack>
                </Box>
              )
            )}
          </JoinChildren>
        </Stack>
      </Paper>
      <Stack direction="row" justifyContent="flex-end" height="40px" mt={1}>
        <Button href={externalLink} target="_blank" startIcon={<PlusIcon />}>
          {t("moreDetails")}
        </Button>
      </Stack>
    </>
  );
}
