import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Button, { ButtonProps } from "@mui/material/Button";
import ButtonGroup, { ButtonGroupProps } from "@mui/material/ButtonGroup";
import { MouseEventHandler } from "react";

export interface ForwardBackButtonsProps extends ButtonProps {
  onBackClick: MouseEventHandler<HTMLButtonElement>;
  backDisabled?: boolean;
  onForwardClick: MouseEventHandler<HTMLButtonElement>;
  forwardDisabled?: boolean;
  sx?: ButtonGroupProps["sx"];
}

export function ForwardBackButtons({
  onBackClick,
  onForwardClick,
  backDisabled,
  forwardDisabled,
  sx,
  ...buttonProps
}: ForwardBackButtonsProps) {
  return (
    <ButtonGroup sx={sx}>
      <Button {...buttonProps} onClick={onBackClick} disabled={backDisabled}>
        <ChevronLeft />
      </Button>
      <Button
        {...buttonProps}
        onClick={onForwardClick}
        disabled={forwardDisabled}
      >
        <ChevronRight />
      </Button>
    </ButtonGroup>
  );
}
