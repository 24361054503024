import { SearchResult } from "../defs";
import { SearchItemLibrary } from "./SearchItemLibrary";
import { SearchItemDocument } from "./SearchItemDocument";
import { SearchItemEvent } from "./SearchItemEvent";
import { SearchItemNews } from "./SearchItemNews";
import { SearchItemPage } from "./SearchItemPage";

export function SearchItem(props: SearchResult) {
  const { itemType } = props;
  if (itemType === "DmsLibrary") {
    return <SearchItemLibrary {...props} />;
  }
  if (itemType === "DmsDocument") {
    return <SearchItemDocument {...props} />;
  }
  if (itemType === "Event") {
    return <SearchItemEvent {...props} />;
  }
  if (itemType === "News") {
    return <SearchItemNews {...props} />;
  }
  if (itemType === "Page") {
    return <SearchItemPage {...props} />;
  }
  return null;
}
