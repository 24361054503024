import Typography from "@mui/material/Typography";
import { PageContainer } from "core/components/PageContainer";
import { useTranslation } from "react-i18next";

export function NotFound404Page() {
  const { t } = useTranslation("Fixhub", {
    keyPrefix: "Next:Core:NotFound404Page",
  });
  return (
    <PageContainer maxWidth={false}>
      <Typography variant="h3" color="error.main">
        {t("title")}
      </Typography>
    </PageContainer>
  );
}
