import { useBreakpoints } from "core/hooks/useBreakpoints";
import { usePageLogging } from "core/hooks/usePageLogging";
import { ItemType } from "core/entities";
import Head from "next/head";
import { MyLinksIcon } from "features/MyLinks/components/MyLinksIcon";
import LinearProgress from "@mui/material/LinearProgress";
import { useState } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { ErrorComponent } from "core/components/ErrorComponent";
import { StyledTabs } from "core/components/StyledTabs";
import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LoadingButton from "@mui/lab/LoadingButton";
import { Container } from "@mui/material";
import { useGlobalSearch } from "./useGlobalSearch";
import { SearchItem } from "./SearchItem/SearchItem";

type TabValue = Exclude<ItemType, "External"> | "All";
const tabValues: TabValue[] = [
  "All",
  "Page",
  "DmsLibrary",
  "DmsDocument",
  "News",
  "Event",
];

export function SearchPage() {
  usePageLogging({
    itemType: "Page",
    itemId: "search",
    componentName: "SearchPage",
  });
  const { t } = useTranslation("Fixhub", { keyPrefix: "Next:Core:SearchPage" });
  const [tabValue, setTabValue] = useState<TabValue>("All");
  const {
    query: { filterText: queryFilterText },
  } = useRouter();

  const filterText = String(queryFilterText);
  const {
    items,
    totalCount,
    currentCount,
    showLoadMore,
    loadMore,
    isLoadingMore,
    isLoadingInitialData,
    isError,
  } = useGlobalSearch(filterText, tabValue === "All" ? undefined : tabValue);
  const { isMobile } = useBreakpoints();
  return (
    <>
      <Head>
        <title>{t("headTitle", { filterText })}</title>
      </Head>
      <Container maxWidth="xl">
        <Typography variant="h3" component="h1" color="primary" mb={3}>
          {t("title", {
            filterText,
          })}
        </Typography>
        <StyledTabs value={tabValue} variant="scrollable">
          {tabValues.map((val) => {
            const displayTabText =
              !isMobile || val === "All" || val === tabValue;
            return (
              <Tab
                key={val}
                value={val}
                label={
                  <Stack direction="row" alignItems="center" gap={1}>
                    {val !== "All" && <MyLinksIcon itemType={val} />}
                    {displayTabText && t(val)}
                  </Stack>
                }
                onClick={() => {
                  setTabValue(val);
                }}
              />
            );
          })}
        </StyledTabs>
        <Divider sx={{ mb: 4 }} />
        <Typography sx={{ display: "block", px: 2, mb: 2 }} variant="overline">
          {t("listTitle", {
            currentCount,
            totalCount,
          })}
        </Typography>
        {isLoadingInitialData && <LinearProgress />}
        {!isLoadingInitialData && !isError && items.length === 0 && (
          <Typography variant="h5" sx={{ px: 2 }}>
            {t("noEntities", {
              itemType: tabValue === "All" ? t("items") : t(tabValue),
            })}
          </Typography>
        )}
        {isError && <ErrorComponent sx={{ ml: 2 }} apiError={isError} />}
        {!isLoadingInitialData &&
          !isError &&
          items.map((searchItemProps) => (
            <SearchItem key={searchItemProps.id} {...searchItemProps} />
          ))}
        {showLoadMore && (
          <Box display="flex" justifyContent="center">
            <LoadingButton
              variant="outlined"
              startIcon={<ExpandMoreIcon />}
              loading={isLoadingMore}
              onClick={loadMore}
            >
              {t("showMore")}
            </LoadingButton>
          </Box>
        )}
      </Container>
    </>
  );
}
