import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import { BrandLogo } from "core/components/BrandLogo";
import Toolbar from "@mui/material/Toolbar";
import { UserMenu } from "./UserMenu";

export function LogoutPageHeader() {
  return (
    <Box
      component="header"
      sx={{ flexGrow: 1, boxShadow: (theme) => theme.shadows[1], zIndex: 1 }}
    >
      <AppBar position="static" component="section">
        <Toolbar
          disableGutters
          sx={{ px: 3, justifyContent: "flex-end", gap: 3 }}
        >
          <UserMenu menuItems={[]} />
        </Toolbar>
      </AppBar>
      <Stack
        component="nav"
        direction="row"
        alignItems="center"
        px={6}
        py={1}
        spacing={3}
        bgcolor="background.paper"
        maxWidth="100vw"
      >
        <Box width={150}>
          <BrandLogo />
        </Box>
      </Stack>
    </Box>
  );
}
