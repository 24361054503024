import { ItemType } from "core/entities";
import { useAPIInfinite } from "core/hooks/useAPIInfinite";
import { useRouter } from "next/router";
import { SearchResult } from "./defs";

export function useGlobalSearch(
  filterText: string,
  itemType?: Exclude<ItemType, "External">
) {
  const { locale } = useRouter();
  const {
    data,
    items,
    totalCount,
    isLoadingInitialData,
    isLoadingMore,
    isError,
    mutate,
    currentCount,
    loadMore,
    showLoadMore,
  } = useAPIInfinite<SearchResult>(
    {
      path: "/api/app/search",
      params: { filterText, locale, itemType },
    },
    { revalidateOnMount: true, revalidateOnReconnect: true }
  );

  return {
    data,
    items,
    totalCount,
    isLoadingInitialData,
    isLoadingMore,
    isError,
    mutate,
    currentCount,
    loadMore,
    showLoadMore,
  };
}
