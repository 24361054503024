import { GenericAPIData } from "core/entities";
import { useAPIAll } from "core/hooks/useAPIAll";
import { useRouter } from "next/router";

export function useNewsEventsTags(type: "news" | "events") {
  const { locale } = useRouter();
  const { data, isError, isLoading, mutate } = useAPIAll<
    GenericAPIData<string>
  >({ path: "/api/news-events/articles/Tags", params: { locale, type } });

  return { data, isError, isLoading, mutate };
}
